import React from 'react';
import Jumbotron from '../../components/Jumbotron';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../../components/Layout';
import CatalogueRoll from '../../components/CatalogueRoll';

import ContentContainer from '../../components/Layouts/ContentContainer';
import Section from '../../components/Layouts/ContentLayout/Section';

// Export so CMS Preview page can use it.
export const CatagoryIndexPageTemplate = ({ jumbotron, cards }) => {
  return (
    <div>
      <Jumbotron jumbotron={jumbotron}></Jumbotron>
      <ContentContainer>
        <div className="columns">
          <Section
            title="Products & Services"
            description="See list of our product and services"
          ></Section>
        </div>
        <CatalogueRoll cards={cards}></CatalogueRoll>
      </ContentContainer>
    </div>
  );
};

const CatagoryIndexPage = ({ data }) => {
  const { frontmatter } = data.settings;
  const { nodes } = data.cardsData;
  const cards = nodes.map((node) => {
    const frontmatter = node.frontmatter;
    return {
      title: frontmatter.name,
      subtitle: 'Category',
      image: frontmatter.featuredimage,
      link: node.fields.slug,
    };
  });

  return (
    <Layout>
      <CatagoryIndexPageTemplate
        jumbotron={frontmatter.jumbotron}
        cards={cards}
      ></CatagoryIndexPageTemplate>
    </Layout>
  );
};

const pageQuery = graphql`
  query CategoriesIndexPageQuery {
    settings: markdownRemark(
      frontmatter: { settingKey: { eq: "category-index-settings" } }
    ) {
      frontmatter {
        title
        jumbotron {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          subheading
        }
      }
    }
    cardsData: allMarkdownRemark(
      filter: {
        frontmatter: { queryKey: { eq: "categories" }, parent: { eq: null } }
      }
    ) {
      nodes {
        frontmatter {
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
          name
          parent
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default () => {
  return (
    <StaticQuery
      query={pageQuery}
      render={(data) => <CatagoryIndexPage data={data}></CatagoryIndexPage>}
    ></StaticQuery>
  );
};
