import React from 'react';

import { useSpring, animated } from 'react-spring';

const Jumbotron = (props) => {
  const jumbotron = props.jumbotron;

  const spring = useSpring({
    from: { opacity: 0, transform: 'scale(1.1)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });

  const textSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(40px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 350,
  });

  const textSpring2 = useSpring({
    from: { opacity: 0, transform: 'translateY(40px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 450,
  });

  return (
    <animated.div
      native
      className={`full-width-image margin-top-0 jumbotron`}
      style={{
        backgroundImage: `url(${
          jumbotron.image && jumbotron.image.childImageSharp
            ? jumbotron.image.childImageSharp.fluid.src
            : jumbotron.image
        })`,
        ...spring,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <animated.h1
          native
          style={textSpring}
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        >
          {jumbotron.heading}
        </animated.h1>
        {jumbotron.subheading && (
          <animated.h3
            native
            style={textSpring2}
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          >
            {jumbotron.subheading}
          </animated.h3>
        )}
      </div>
    </animated.div>
  );
};

export default Jumbotron;
